<template>
  <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <hippo class="text-primary mx-auto h-10" />
      <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
        Sign in to your account
      </h2>
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <form-kit
        type="form"
        form-class="space-y-6"
        :actions="false"
        incomplete-message=" "
        @submit="onSignIn">
        <form-kit
          id="email"
          name="email"
          type="email"
          validation="required|email"
          validation-visibility="submit"
          autocomplete="email"
          label="Email address"
          :classes="{
            input: `focus:lily-purple block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 mt-1`,
            label: `block text-sm font-light text-black`,
            message: `block text-sm font-medium text-red-500`,
          }" />
        <form-kit
          id="password"
          name="password"
          type="password"
          validation="required"
          autocomplete="current-password"
          label="Password"
          :classes="{
            input: `focus:lily-purple block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 mt-1`,
            label: `block text-sm font-light text-black`,
            message: `block text-sm font-medium text-red-500`,
          }" />
        <nuxt-link
          to="/forgot"
          class="text-lily-purple-100 hover:text-lily-purple text-sm font-medium leading-6"
          >Forgot your password?</nuxt-link
        >
        <div>
          <form-kit
            type="submit"
            label="Sign in"
            input-class="flex w-full justify-center rounded-md bg-lily-purple-100 px-3 py-1.5 text-sm font-normal leading-6 text-white shadow-sm hover:bg-lily-purple focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lily-purple" />
        </div>
      </form-kit>
      <p class="mt-10 text-center text-sm text-gray-500">
        Not a user?
        {{ " " }}
        <a
          :href="signUpUrl"
          class="text-lily-purple-100 hover:text-lily-purple font-medium leading-6"
          >Sign up</a
        >
      </p>
    </div>
  </div>
</template>
<script setup>
import Hippo from "~/assets/images/Hippo.svg";
import { signIn } from "~/utils/auth/session";
const {
  public: { homeUrl },
} = useRuntimeConfig();
const signUpUrl = `${homeUrl}/signup`;

const onSignIn = async (form, node) => {
  const { email, password } = form;
  try {
    await signIn(email, password);
    await navigateTo("/");
  } catch (e) {
    switch (e.code) {
      case "auth/invalid-email":
      case "auth/wrong-password":
      case "auth/user-not-found":
        node.setErrors(["You have entered an incorrect email or password."]);
        break;
      default:
        node.setErrors(["An unknown error occured, please contact support."]);
        // TODO: Log error to Sentry
        throw e;
    }
  }
};
</script>
